.projects-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.projects-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.projects-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.back-container {
	margin-top: 10px;
	padding: 10px;
	height: 100%;
	color: var(--link-color);
	font-size: 50px;
}

.back-container:hover {
	cursor: pointer;
}


.project-back-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: -30px;
}

.project-back-title {
	margin-left: 20px;
	font-size: 50px;
}

.project-back-img {
	margin-left: 40px;
	width: 60px;
}

.projects-tech-skills-hint {
	margin-top: -20px;
	margin-left: 40px;
	color: var(--secondary-color);
}

.projects-subtitle {
	margin-left: 10px;
}

.projects-list {
	margin-top: 30px;
}

.projects-teck-skills {
	margin-top: 60px;
}

.back-skill-container {
	margin-top: 0;
}


.color-link {
	color: var(--link-color);
}

@media (max-width: 600px) {
	.projects-container {
		padding-top: 60px;
	}

	.projects-project {
		width: calc(100% / 2);
	}


	.back-container {
		font-size: 30px;
	}

	.project-back-title {
		margin-left: 20px;
		font-size: 30px;
	}

	.project-back-img {
		margin-left: 30px;
		width: 35px;
	}
}