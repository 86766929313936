.contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.contact-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.contact-subtitle {
	width: 100% !important;
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
}


.socials-container {
	display: flex;
	flex-direction: column;
	margin-top: 80px;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.link-color {
	color: var(--link-color);
}

.contact-wrapper {
	min-height: 70vh;
}

.contact-subtitle {
	min-height: 25vh;
}