.git-contribute-mobile {
    display: none;
}

@media (max-width: 800px) {

    .git-contribute-destop {
        display: none;
    }

    .git-contribute-mobile {
        display: block;
    }
}