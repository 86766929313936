.skill {
	mix-blend-mode: normal;
	border-radius: 20px;
    background-color: #fafafa;
    padding: 10px;
}

.skill-logo {
    width: 100%;
    height: auto;
}

.container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
	border-radius: 20px;
  opacity: 0;
  transition: .5s ease;
  background-color: var(--link-color);
}

.container:hover .overlay {
  opacity: 0.9;
}

.container:hover .overlay-pointer {
    cursor: pointer;
}

.overlay-text {
  color: white;
  font-size: 20px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
