@import "../../../data/styles.css";

.full-width-project {
    width: 100%;
    box-sizing: border-box;
    border-bottom: solid var(--quaternary-color);
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: hidden;
}

.full-project {
    mix-blend-mode: normal;
    border-radius: 20px;
    opacity: 0.8;
    height: 100%;
}

.full-project a {
    text-decoration: none;
}

.full-project:hover {
    background: #fafafa;
    opacity: 1;
    transition: background-color 0.3s ease-in-out;
}

.full-project:hover .full-width-project-title {
    color: var(--link-color);
    transition: color 0.3s ease-in-out;
}

.full-width-project-container {
    padding: 30px;
}

.full-width-project-left-side {
	min-width: 80%;
	max-width: 80%;
}

.full-width-project-date {
    padding-top: 10px;
	font-size: 13px;
	color: var(--tertiary-color);
}

.full-width-project-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-width-project-image-container {
    width: 80%;
}

.full-width-project-image-container img {
    width: 100%;
    height: 400px;
	border: 1px solid  var(--tertiary-color);
	border-radius: 22px;
}

.full-width-project-title {
    font-family: var(--secondary-font);
    color: var(--primary-color);
    font-size: 16px;
    padding-top: 15px;
    margin-bottom: 1em;
    font-weight: 700;
}

.full-width-project-techstacks {
    display: flex;
    flex-direction: row;
}

.full-width-project-tech {
    width: 50px;
    margin: 10px;
}

.full-width-project-tech img {
    width: 100%;
	border-radius: 15%;
}

.full-width-project-description {
    color: var(--secondary-color);
}

.full-width-project-description-text {
    display: none;
}

.full-width-project-description ul {
    padding: 0;
}

.full-width-project-description ul li {
    list-style: none;
}

.full-width-project-description ul li::before {
    content: "- ";
}

.full-width-project-link {
    display: flex;
    align-items: center;
    color: var(--secondary-color);
    font-size: 12px;
    margin-top: -10px;
    margin-left: 20px;
    padding-bottom: 20px;
}

.full-width-project-link-icon {
    padding-left: 5px;
    font-size: 13px;
}

.full-width-project-link-text {
    padding-left: 20px;
    font-weight: 700;
}

.full-width--projects-project {
    width: calc(100% / 3);
    box-sizing: border-box;
    padding: 10px;
    overflow: hidden;
}

@media (max-width: 900px) {
    .full-width-project-image-container img {
        width: 100%;
        height: 300px;
    }


    .full-width-project-description-text {
        display: block;
    }

    .full-width-project-description ul {
        display: none;
    }
}

@media (max-width: 600px) {
    .full-project {
        height: 100%;
    }

    .all-projects-project {
        width: calc(100% / 2);
    }

    .full-width-project-image-container img {
        width: 100%;
        height: 200px;
    }


.full-width-project-tech {
    width: calc(100% / 8);
}
}