.skills-body {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding-bottom: 30px;
    padding-left: 30px;
	margin-top: -10px;
	margin-left: -35px;
	margin-right: -35px;
}

.skills-skill {
	width: calc(100% / 8);
	box-sizing: border-box;
	padding: 10px;
	overflow: hidden;
}

@media (max-width: 600px) {
	.skills-skill {
		width: calc(100% / 4);
	}
}


