@import "../../../data/styles.css";

.project {
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
}

.project a {
	text-decoration: none;
}

.project:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.project:hover .project-title {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-container {
	padding: 30px;
}

.project-logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.project-logo {
	width: 200px;
	height: 100px;
}

.project-logo img {
	border: 1px solid  var(--tertiary-color);
	border-radius: 3px;
	width: 100%;
	height: 100%;
}

.project-techs {
	margin: 5px;
	width: 100%;
	display: flex;
	flex-direction: row;
}

.project-tech {
	width: calc(100%/8);
	padding: 5px;
}

.project-tech img {
	width: 100%;
	border-radius: 15%;
}

.project-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.project-description {
	color: var(--secondary-color);
}

.before-link-container {
	min-height: 350px;
}

.project-link-container {
	display: flex;
	align-items: center;
	padding-top: 20px;
}

.project-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	margin: 5px;
}

.project-github-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	margin: 5px;
}

.project-link:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-link:hover .project-github-link {
	color: var(--secondary-color);
}

.project-github-link:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-github-link:hover .project-link div  {
	color: var(--secondary-color);
	font-size: 18px;
}

.project-link-icon {
	font-size: 13px;
}

.project-link-text {
	margin-left: 10px;
	font-weight: 700;
}

@media (max-width: 600px) {
	.project {
		height: 100%;
	}
}
